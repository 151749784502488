import { Archivo } from './archivo.model';
import { Mercancia } from './mercancia.model';
import { Time } from '@angular/common';
import { HSCode } from './hscode.model';
import { ContenedorMercancia } from './contenedorMercancia.model';
import { TarifaEnvio } from './tarifaEnvio';

export class Shipment {
    IdEnvio?: number;
    IdArea: number;
    IdModoEnvio: number;
    IdTipoEnvio: number;
    IdCliente: number;
    RefCliente: string;
    IdRemitente: number;
    Remitente: string;
    DireccionShipper: string;
    Destinatario: string;
    DireccionConsignee: string;
    IdDestinatario: number;
    IdNotify: number;
    chShipper: Boolean;
    chConsignee: Boolean;
    chNotify: Boolean;
    DireccionNotify: string;
    Incoterm: string;
    TipoMercancia: number;
    ValorMercancia: string;
    IdTipoDivisa: number;
    Viaje: string;
    HSCodes: HSCode[];
    CosteSeguro: number;
    Asegurado: number;
    IdAeropuertoOrigen: number;
    IdAeropuertoDestino: number;
    Aerolinea: string;
    IdPuertoOrigen: number;
    IdPuertoDestino: number;
    Naviera: string;
    IdContenedor: number;
    IdEntidadRecogida: number;
    IdDireccionRecogida: number;
    ReferenciaRecogida: string;
    InstruccionesRecogida: string;
    FechaRecogida: Date;
    PrimeraHoraRecogidaInicio: Time;
    PrimeraHoraRecogidaFinal: Time;
    SegundaHoraRecogidaInicio: Time;
    SegundaHoraRecogidaFinal: Time;
    PersonaContactoRecogida: string;
    TelefonoRecogida: string;
    EmailRecogida: string;
    DespachoOrigen: string;
    DespachoDestino: string;
    IdEntidadEntrega: number;
    IdDireccionEntrega: number;
    ReferenciaEntrega: string;
    InstruccionesEntrega: string;
    PersonaContactoEntrega: string;
    TelefonoEntrega: string;
    EmailEntrega: string;
    PrimeraHoraEntregaInicio: Time;
    PrimeraHoraEntregaFinal: Time;
    SegundaHoraEntregaInicio: Time;
    SegundaHoraEntregaFinal: Time;
    MercanciaPeligrosa: number;
    PesoNetoTotalDGR: number;
    PesoBrutoTotalDGR: number;
    BultosTotales: number;
    PesoNetoTotal: number;
    PesoBrutoTotal: number;
    VolumenTotal: number;
    PesoTasable: number;
    InstruccionesBLAWB: string;
    Marcas: string;
    Instrucciones: string;
    Refrigerada?: boolean;
    Perecedero?: boolean;
    NoRemontable?: number;
    Fumigado?: boolean;
    Estado?: string;
    DiasRetraso?: number;
    Partida?: string;
    Expedicion?: string;
    Archivos?: Archivo[];
    Mercancia?: Mercancia[];
    MercanciaContenedor?: ContenedorMercancia[];
    FechaEntrega?: Date;
    FechaLimiteSubidaDocumentacion?: Date;
    IdCotizacion: string;
    VGMPesaje: boolean;
    VGMTransmision: boolean;
    RefCotizacion: string;
    HayIncidencias: boolean;
    Tarifa: TarifaEnvio;
    IdUsuarioMyXGL: number;
}
