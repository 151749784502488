import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

// #region Librerías que se utilizan normalmente
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
// #endregion Librerías que se utilizan normalmente
// #region Headers
import { HeaderLanguageComponent } from '../../components/header/header-language/header-language.component';
import { HeaderFijoComponent } from '../../components/header/header-fijo/header-fijo.component';
import { HeaderComponent } from '../../components/header/header.component';
import { HeaderProveedorComponent } from 'src/app/components/users/proveedor/header-proveedor/header-proveedor.component';
import { HeaderComponent as HeaderConductorComponent } from '../../components/users/conductor/header/header.component';
import { HeaderComponent as HeaderNoLogin } from '../../components/header/header.component';
import { HeaderComponent as HeaderCustomer } from 'src/app/components/users/customers/header/header.component';
import { TranslateModule } from '@ngx-translate/core';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { NgbPaginationModule, NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap'
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { MapaComponent } from 'src/app/components/componentesAuxiliares/mapa/mapa.component';
import { AgmCoreModule } from '@agm/core';
// #endregion Headers


@NgModule({
  declarations: [
    HeaderFijoComponent,
    HeaderLanguageComponent,
    HeaderComponent,
    HeaderConductorComponent,
    HeaderProveedorComponent,
    HeaderNoLogin,
    HeaderCustomer,
    MapaComponent,
  ],
  imports: [
    //RouterModule,
    CommonModule,
    NgSelectModule,
    Ng2SearchPipeModule,
    Ng2OrderModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    AngularMaterialModule,
    NgbPaginationModule,
    NgbModule,
    NgbPopoverModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD21qg2Wj9wgL8s6Dod6PdJsAu3g-FeAIQ',
      libraries: ['places']
    }),
  ],
  exports: [
    // Componentes
    HeaderFijoComponent,
    HeaderLanguageComponent,
    HeaderComponent,
    HeaderConductorComponent,
    HeaderProveedorComponent,
    HeaderNoLogin,
    HeaderCustomer,
    MapaComponent,
    // Modules
    CommonModule,
    AngularMaterialModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2OrderModule,
    Ng2SearchPipeModule,
    TranslateModule,
    NgbPaginationModule,
    NgbModule,
    NgbPopoverModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    AgmCoreModule,
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class SharedModule { }
